import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)
  const [ emailSent, setEmailSent] = useState(false)
  const [ emailLoad, setEmailLoad] = useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    setEmailLoad(true);
    fetch('https://prms.cl/mailer.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({name:name,email:email,desc:message}),
    })
    .then(
          (result) => {
           // console.log(result.json())
            clearState();
            setEmailSent(true);
    })
  
    // emailjs
    //   .sendForm(
    //     'service_9wlhejr', 'template_n4dkfej', e.target, 'user_3S8RK4YgZCh4k2mSoEXQa'
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text)
    //       clearState();
    //       setEmailSent(true);

    //     },
    //     (error) => {
    //       console.log(error.text)
    //     }
    //   )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
        
          

            <div className='row'>

            
              <div className='section-title'>
              
                <h2>
                  {!emailSent && (props.data ? props.data.title : 'loading')}
                  {emailSent && (props.data ? props.data.successTitle : 'loading')}
                </h2>
          
                 <p>
                  {!emailSent && (props.data ? props.data.paragraph : 'loading')}
                  {emailSent && (props.data ? props.data.successParagraph : 'loading')}
                </p>
                {emailLoad &&  
              <div class="loader" id="loader-6">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
          }
          </div>
              {!emailLoad && !emailSent && <form name='sentMessage' validate onSubmit={handleSubmit}  method="POST">
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder={props.data ? props.data.formName : 'loading'}
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder={props.data ? props.data.formMessage : 'loading'}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  {props.data ? props.data.sendTitle : 'loading'}
                </button>
              </form>}
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>{props.data ? props.data.dataTitle : 'loading'}</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> 
                  {props.data ? props.data.addressTitle : 'loading'}
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> 
                  {props.data ? props.data.phoneTitle : 'loading'}
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  {/* <li>
                    <a target="_blank" href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                   */}
                  {/*  <li>
                    <a target="_blank" href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                   */}
                   <li>
                    <a target="_blank" href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; {new Date().getFullYear()} PRMS. 
            
          </p>
        </div>
      </div>
    </div>
  )
}
