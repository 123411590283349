import {useState} from "react";

export const Gallery = (props) => {
  const [ modalState, setModal] = useState(); 
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>{props.data ? props.data.title : 'Loading'}</h2>
          <p>
            {props.data ? props.data.paragraph : 'Loading'}
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              {/* <div > */}
              <div className='portfolio-item' onClick={() => setModal('img/portfolio/web/artv-1.jpeg')} data-toggle="modal" data-target="#largeModal">
                <div className='hover-bg' >
                  {' '}
                  {/* <a
                    href='img/portfolio/web/artv-1.jpeg'
                    title='Project Title'
                    data-lightbox='gallery1'
                    //
                  > */}
                    <div className='hover-text'>
                      <h4>Artv</h4>
                    </div>
                    <img
                      src='img/portfolio/web/artv-sm.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  {/* </a> */}{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
            <div className='portfolio-item' onClick={() => setModal('img/portfolio/web/ban.jpeg')} data-toggle="modal" data-target="#largeModal">
                <div className='hover-bg'>
                  {' '} 
                    <div className='hover-text'>
                      <h4>Banmedica</h4>
                    </div>
                    <img
                      src='img/portfolio/web/ban-sm.jpg'
                      className='img-responsive'
                      alt='Banmedica'
                    />
                    {' '}
                    {' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
            <div className='portfolio-item' onClick={() => setModal('img/portfolio/web/kankana.jpeg')} data-toggle="modal" data-target="#largeModal">
                <div className='hover-bg'>
                  {' '}
                    <div className='hover-text'>
                      <h4>Kankana del Elqui</h4>
                    </div>
                    <img
                      src='img/portfolio/web/kan-sm.jpg'
                      className='img-responsive'
                      alt='Kankana del Elqui'
                    />{' '}
                  {' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
            <div className='portfolio-item' onClick={() => setModal('img/portfolio/web/viax.jpeg')} data-toggle="modal" data-target="#largeModal">
                <div className='hover-bg'>
                   {' '}
                    <div className='hover-text'>
                      <h4>ViaX</h4>
                    </div>
                    <img
                      src='img/portfolio/web/valdi-sm.jpg'
                      className='img-responsive'
                      alt='Valdivieso'
                    />{' '}
                  {' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
            <div className='portfolio-item' onClick={() => setModal('img/portfolio/video/quilicura.mp4')} data-toggle="modal" data-target="#largeModal">
                <div className='hover-bg'>
                  {' '}
                    <div className='hover-text'>
                      <h4>Cushman & Wakefield</h4>
                    </div>
                    
                    <img
                      src='img/portfolio/video/quilicura.jpg'
                      className='img-responsive'
                      alt='Viax'
                    />{' '}
                  {' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
            <div className='portfolio-item' onClick={() => setModal('img/portfolio/web/viax2.jpeg')} data-toggle="modal" data-target="#largeModal">
                <div className='hover-bg'>
                  {' '}
                    <div className='hover-text'>
                      <h4>Viax 2</h4>
                    </div>
                    <img
                      src='img/portfolio/web/viax2-sm.jpg'
                      className='img-responsive'
                      alt='Viax 2'
                    />{' '}
                  {' '}
                </div>
              </div>
            </div>
            {/* <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/07-large.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Dolor Sit</h4>
                    </div>
                    <img
                      src='img/portfolio/07-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/08-large.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Lorem Ipsum</h4>
                    </div>
                    <img
                      src='img/portfolio/08-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  {' '}
                  <a
                    href='img/portfolio/09-large.jpg'
                    title='Project Title'
                    data-lightbox-gallery='gallery1'
                  >
                    <div className='hover-text'>
                      <h4>Adipiscing Elit</h4>
                    </div>
                    <img
                      src='img/portfolio/09-small.jpg'
                      className='img-responsive'
                      alt='Project Title'
                    />{' '}
                  </a>{' '}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>



    <div class="modal fade" id="largeModal" tabindex="-1" role="dialog" aria-labelledby="largeModal" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          {modalState?.split('.').pop()==='mp4' ? <video width="100%" controls>
            <source src={modalState} type="video/mp4" /></video> : 
            <img src={modalState} width="100%" />}
        </div>
      </div>
    </div>



    </div>
    
    
  )
}
